import React, { useCallback } from 'react';
import { ExpandedArticleType, Tag } from '../../utils/blogTypes';
import ArticleTag, { TagTheme, TagType } from '../ArticleTag';
import Text from '../Text';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { formatTimestamp } from '../../utils/dateHelpers';
import CalendarDoneIcon from '../icons/CalendarDoneIcon';
import TimeIcon from '../icons/TimeIcon';
import CalendarRepeatIcon from '../icons/CalendarRepeatIcon';
import classNames from 'classnames';

type ArticleHeaderProps = Pick<ExpandedArticleType, 'title' | 'tags'> & {
  siteUrl: string;
  readTime: number;
  createdAt: string;
  updatedAt: string;
  className?: string;
};

const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  title,
  tags,
  readTime,
  createdAt,
  updatedAt,
  className,
}) => {
  const { t } = useTranslation();

  const formattedDate = formatTimestamp(updatedAt ? updatedAt : createdAt);

  const mergedClassName = classNames('article-header', className);

  const renderTag = useCallback<(tag: Tag) => JSX.Element>(
    tag => (
      <ArticleTag
        tagType={TagType.AUTO}
        href={`/blog/tag/${tag}/`}
        theme={TagTheme.LIGHT}
        tagValue={tag}
      />
    ),
    []
  );

  return (
    <div className={mergedClassName}>
      <div className="article-header__content">
        <div className="article-header__tags">{tags.map(renderTag)}</div>
        <Text
          type="h1"
          color="light"
          weight="bold"
          className="article-header__title"
        >
          {title}
        </Text>

        <div className="article-header__info">
          <div className="article-header__info-item">
            <CalendarDoneIcon />
            <Text
              className="article-header__info-item-title"
              type="p"
              color="light"
            >
              {t('article_created_at', { date: formattedDate })}
            </Text>
          </div>

          <div className="article-header__info-item">
            <TimeIcon />
            <Text
              className="article-header__info-item-title"
              type="p"
              color="light"
            >
              {t('article_read_time', { time: readTime })}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ArticleHeader);
