import classNames from 'classnames';
import React, { CSSProperties, TdHTMLAttributes, useMemo } from 'react';
import { TableCellType } from '../../utils/blogTypes';
import Markdown from '../Markdown';
import './style.scss';

interface TableCellProps extends TableCellType {
  isHeader: boolean;
}

const TableCell: React.FC<TableCellProps> = ({
  rowspan,
  colspan,
  backgroundColor,
  body,
  isHeader,
}) => {
  const cellStyle = useMemo<CSSProperties>(() => ({ backgroundColor }), [
    backgroundColor,
  ]);

  const itemProps = useMemo<TdHTMLAttributes<HTMLTableCellElement>>(
    () => ({
      colSpan: colspan,
      rowSpan: rowspan,
      className: classNames('table-item', 'table-item_centered'),
      style: cellStyle,
    }),
    [cellStyle, colspan, rowspan]
  );

  return isHeader ? (
    <th {...itemProps} className={`${itemProps.className} header-cell`}>
      <Markdown source={body} className="header-cell" />
    </th>
  ) : (
    <td {...itemProps}>
      <Markdown source={body} />
    </td>
  );
};

export default React.memo(TableCell);
