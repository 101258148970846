import { useCallback, useRef, useState } from 'react';
import { OnHeaderInteract } from '../components/TextBlock';
import { ContentsItem } from './useTableOfContents';

const useHeaderInteractionHandler = (contents: ContentsItem[]) => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const hasReachedHeader = useRef(false);

  const onInteractWithHeaders = useCallback<OnHeaderInteract>(
    (id, isScrollingBack) => {
      if (!isScrollingBack) {
        hasReachedHeader.current = true;
      }

      if (!hasReachedHeader.current) {
        return;
      }

      const index = contents.findIndex(item => item.slug === id);
      setCurrentIndex(isScrollingBack ? index - 1 : index);
    },
    [contents]
  );

  return { onInteractWithHeaders, currentIndex };
};

export default useHeaderInteractionHandler;
