import React, { useEffect } from 'react';
import { TradingViewEmbedType } from '../../utils/blogTypes';

interface TradingViewBoxProps extends TradingViewEmbedType {
  className?: string;
}

const TradingViewBox: React.FC<TradingViewBoxProps> = ({
  className,
  chartName,
  chartUrl,
  widgetOptions,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // @ts-ignore
      new (window?.TradingView).widget(JSON.parse(widgetOptions));
    }, 3000);

    return () => clearTimeout(timer);
  }, [widgetOptions]);

  return (
    <div className={`tradingview-widget-container ${className}`}>
      <div id="tradingview_26974"></div>
      <div className="tradingview-widget-copyright">
        <a href={chartUrl} rel="noopener noreferrer" target="_blank">
          <span className="blue-text">{chartName}</span>
        </a>
        by TradingView
      </div>
    </div>
  );
};

export default React.memo(TradingViewBox);
