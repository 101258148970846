import React, { FC, memo } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../icons/CloseIcon';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type Props = {
  isOpen: boolean;
  imageData: IGatsbyImageData;
  altText: string;
  onClose: () => void;
};

const ImageModal: FC<Props> = ({ isOpen, imageData, altText, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      className="modal"
      closeTimeoutMS={200}
      onRequestClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        width={30}
        height={30}
        className="close-button"
      />
      <GatsbyImage
        image={imageData}
        alt={altText}
        className="full-screen-image"
        objectFit="contain"
        onClick={onClose}
      />
    </Modal>
  );
};

export default memo(ImageModal);
