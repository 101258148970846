import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback } from 'react';
import { ContentsItem } from '../../hooks/useTableOfContents';
import Text from '../Text';
import './style.scss';

type TableOfContentsProps = {
  contents: ContentsItem[];
  className?: string;
  titleClassName?: string;
};

export const CONTENTS_ID = 'contents';

const TableOfContents: React.FC<TableOfContentsProps> = ({
  contents,
  className,
  titleClassName,
}) => {
  const { t } = useTranslation();
  const renderContentsItem = useCallback<
    (item: ContentsItem, index: number) => JSX.Element
  >(
    (item, index) => (
      <a href={`#${item.slug}`}>
        <div className="toc-item">
          <div className="item-number toc-item__number">
            <Text type="p" color="dark" weight="bold">
              {index + 1}
            </Text>
          </div>
          <Text
            type="p"
            color="dark"
            weight="demi-bold"
            className="toc-item__body"
          >
            {item.title}
          </Text>
        </div>
      </a>
    ),
    []
  );

  return (
    <div className={className}>
      <Text
        id={CONTENTS_ID}
        type="p"
        color="dark"
        weight="bold"
        className={`contents__title ${titleClassName}`}
      >
        {t('in_this_article_youll_learn')}
      </Text>
      {contents.map(renderContentsItem)}
    </div>
  );
};

export default React.memo(TableOfContents);
