import * as React from 'react';
import { SVGProps, memo } from 'react';

interface FaceIconProps extends SVGProps<SVGSVGElement> {
  isSmiling?: boolean;
}

const FaceIcon = ({ isSmiling, fill = '#29D5AC', ...props }: FaceIconProps) => {
  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 56c13.255 0 24-10.745 24-24S45.255 8 32 8 8 18.745 8 32s10.745 24 24 24Z"
        stroke={fill}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 26.667h.027M40 26.667h.027"
        stroke={fill}
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={
          isSmiling
            ? 'M25.332 40a9.335 9.335 0 0 0 13.333 0'
            : 'M25.332 40.667a9.335 9.335 0 0 1 13.333 0'
        }
        stroke={fill}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(FaceIcon);
