import React, { useCallback } from 'react';
import { CardsBoxType, CardType } from '../../utils/blogTypes';
import CardBoxIcon from '../CardBoxIcon';
import Markdown from '../Markdown';
import Text from '../Text';
import './style.scss';

interface CardBoxProps extends CardsBoxType {
  className?: string;
}

const CardsBox: React.FC<CardBoxProps> = ({ className, cardsWithMedals }) => {
  const renderCard = useCallback<
    (card: CardType, index: number) => JSX.Element
  >(
    (card, index) => (
      <div className="box-card cards-wrapper__box-card">
        <CardBoxIcon index={index} {...card} />
        <Text
          type="h3"
          color="dark"
          weight="bold"
          className="box-card__title h4"
        >
          {card.title}
        </Text>
        <Markdown className="box-card__content" source={card.body} />
      </div>
    ),
    []
  );

  return (
    <div className={`cards-wrapper ${className}`}>
      {cardsWithMedals.map(renderCard)}
    </div>
  );
};

export default React.memo(CardsBox);
