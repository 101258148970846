import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useCallback, useMemo, useState } from 'react';
import { CaptionedImageBoxType } from '../../utils/blogTypes';
import Markdown from '../Markdown';
import './style.scss';
import ImageModal from '../ImageModal';
import { not } from 'ramda';

interface CaptionedImageBoxProps extends CaptionedImageBoxType {
  className?: string;
}

const CaptionedImageBox: React.FC<CaptionedImageBoxProps> = ({
  className,
  mainImage,
  caption,
  altText,
  imageLink,
  imageHeight,
}) => {
  const imageData = useMemo(
    () => getImage(mainImage.asset),
    [mainImage.asset]
  )!;

  const [isFullScreen, setIsFullscreen] = useState(false);

  const toggleFullScreen = useCallback(() => {
    setIsFullscreen(not);
  }, []);

  const Image = useMemo(
    () => (
      <div className={`image-box ${className}`} onClick={toggleFullScreen}>
        <GatsbyImage
          image={imageData}
          alt={altText ?? ''}
          style={{ height: imageHeight }}
          objectFit="contain"
        />
        <Markdown source={caption ?? ''} className="markdown" />
      </div>
    ),
    [altText, caption, className, imageData, imageHeight, toggleFullScreen]
  );

  return imageLink ? (
    <a href={imageLink} rel="noreferrer" target="_blank" className={className}>
      {Image}
    </a>
  ) : (
    <>
      {Image}
      <ImageModal
        imageData={imageData}
        altText={altText ?? ''}
        isOpen={isFullScreen}
        onClose={toggleFullScreen}
      />
    </>
  );
};

export default React.memo(CaptionedImageBox);
