import React, { CSSProperties, useMemo } from 'react';
import { ReadAlsoBoxType } from '../../utils/blogTypes';
import LampIcon from '../icons/LampIcon';
import Link from '../Link';
import Text from '../Text';
import './style.scss';

interface ReadAlsoBoxProps extends ReadAlsoBoxType {
  className?: string;
}

const ReadAlsoBox: React.FC<ReadAlsoBoxProps> = ({
  className,
  body,
  backgroundColor,
  title,
  link,
}) => {
  const containerStyle = useMemo<CSSProperties>(() => ({ backgroundColor }), [
    backgroundColor,
  ]);

  return (
    <Link className={className} href={link}>
      <div style={containerStyle} className={`read-also-box`}>
        <div className="lamp read-also-box__lamp">
          <LampIcon fill={backgroundColor} />
        </div>

        <div>
          <Text
            type="p"
            color="light"
            weight="bold"
            className="read-also-box__text read-also-box__title"
          >
            {title}
          </Text>
          <Text type="p" color="light" className="read-also-box__text">
            {body}
          </Text>
        </div>
      </div>
    </Link>
  );
};

export default React.memo(ReadAlsoBox);
