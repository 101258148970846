import * as React from 'react';
import { SVGProps, memo } from 'react';

const ArrowFive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={184}
    height={188}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.7} fillRule="evenodd" clipRule="evenodd" fill="#fff">
      <path d="M101.81 98.537c3.511-1.895 6.025-4 7.855-5.743 17.434-16.733 10.639-38.855-1.508-56.156-3.252-4.637-9.806-10.646-16.56-12.898-4.745-1.585-9.58-1.355-13.557 2.197-4.35 3.855-5.87 10.28-5.68 17.313.268 10.173 4.074 21.618 7.263 27.804 3.68 7.134 10.542 17.525 18.548 26.771-3.947 1.746-8.96 3.169-15.374 3.657-2.85.227-6.141-.256-9.539-1.24 1.04-5.284 1.083-11.01-.033-16.975-.945-5.013-4.905-15.165-10.386-21.66-3.916-4.633-8.618-7.373-13.527-5.782-6.093 1.985-8.209 6.657-8.081 12.07.175 7.62 4.977 16.74 8.708 21.006 4.343 4.962 12.566 10.066 20.663 12.825-4.456 17.67-20.865 29.606-41.28 27.147a1.152 1.152 0 0 0-1.255.983c-.07.622.38 1.189.988 1.256 21.6 2.598 38.99-10.008 43.726-28.7 3.619 1.038 7.145 1.559 10.19 1.319 7.065-.542 12.525-2.162 16.759-4.136a115.721 115.721 0 0 0 4.697 4.949c7.179 7.122 14.857 12.612 21.721 13.925 15.861 3.036 28.169-6.092 35.011-19.1 6.772-12.886 8.148-29.537 2.401-41.603a1.13 1.13 0 0 0-1.506-.535c-.558.27-.799.942-.531 1.504 5.469 11.482 4.095 27.33-2.36 39.583-6.385 12.131-17.799 20.765-32.585 17.94-6.535-1.262-13.74-6.548-20.564-13.316a115.893 115.893 0 0 1-4.204-4.405Zm-30.73 1.018c.936-4.953.97-10.3-.082-15.88-.898-4.772-4.65-14.418-9.889-20.619-3.246-3.843-7.027-6.411-11.094-5.082-4.975 1.608-6.629 5.464-6.526 9.873.145 7.09 4.67 15.593 8.152 19.573 4.077 4.664 11.818 9.508 19.438 12.135Zm29.193-2.748c3.526-1.847 6.022-3.93 7.827-5.642 16.533-15.863 9.724-36.832-1.789-53.231-3.027-4.32-9.118-9.946-15.43-12.057-3.954-1.315-8.018-1.223-11.346 1.743-3.9 3.454-5.094 9.266-4.928 15.563.258 9.818 3.95 20.873 7.022 26.84 3.68 7.134 10.586 17.57 18.644 26.784Z" />
      <path d="M30.228 130.006a430.03 430.03 0 0 0 4.262-2.543c1.18-.756 1.666-1.302 2.097-1.913.34-.495.624-1.054 1.373-1.899.418-.462.356-1.185-.112-1.595a1.135 1.135 0 0 0-1.59.106 13.574 13.574 0 0 0-1.325 1.806c-.358.552-.658.964-1.66 1.595-.485.311-3.37 1.75-4.547 2.668-.616.477-.887.987-.928 1.272-.059.408.025.903.337 1.443.404.692 1.272 1.598 2.223 2.633.896.972 1.887 2.09 2.552 3.32.552 1.037.86 2.149.516 3.319-.18.595.145 1.218.741 1.399a1.13 1.13 0 0 0 1.412-.747c.543-1.754.155-3.465-.687-5.039-.747-1.393-1.85-2.67-2.875-3.779-.61-.665-1.19-1.266-1.603-1.78a2.916 2.916 0 0 1-.186-.266Z" />
    </g>
  </svg>
);

export default memo(ArrowFive);
