import React, { useEffect, useRef } from 'react';
import { GoogleTrendsBoxType } from '../../utils/blogTypes';

interface GoogleTrendsBoxProps extends GoogleTrendsBoxType {
  className?: string;
}

const GoogleTrendsBox: React.FC<GoogleTrendsBoxProps> = ({
  className,
  keywords,
  options,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      (window as any)?.trends.embed.renderExploreWidgetTo(
        ref.current,
        'TIMESERIES',
        JSON.parse(keywords),
        JSON.parse(options)
      );
    }, 2000);

    return () => clearTimeout(timer);
  });

  return <div ref={ref} className={className}></div>;
};

export default React.memo(GoogleTrendsBox);
