import * as React from 'react';
import { memo } from 'react';
import { SocialIconProps } from '../../utils/blogTypes';

const FacebookIcon: React.FC<SocialIconProps> = ({
  pathClassName,
  circleClassName,
  parentClassName,
  ...props
}) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={parentClassName}
    {...props}
  >
    <circle
      cx={24}
      cy={24}
      r={23}
      fill="#fff"
      stroke="#D8D8D8"
      strokeWidth={2}
      className={circleClassName}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m31 19.174-.547 4.825h-4.139V38H20.1V24H17v-4.825h3.099V16.27c0-3.925 1.75-6.27 6.726-6.27h4.138v4.825h-2.588c-1.932 0-2.06.68-2.06 1.937v2.413H31v-.001Z"
      fill="#D8D8D8"
      className={pathClassName}
    />
  </svg>
);

export default memo(FacebookIcon);
