import * as React from 'react';
import { memo } from 'react';
import { SocialIconProps } from '../../utils/blogTypes';

const LinkedinIcon: React.FC<SocialIconProps> = ({
  pathClassName,
  circleClassName,
  parentClassName,
  ...props
}) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={parentClassName}
    {...props}
  >
    <circle
      cx={24}
      cy={24}
      r={23}
      fill="#fff"
      stroke="#D8D8D8"
      strokeWidth={2}
      className={circleClassName}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.65 36V19.132h-5.334V36h5.334Zm-2.668-19.171c1.86 0 3.018-1.296 3.018-2.915C16.965 12.26 15.842 11 14.018 11 12.193 11 11 12.26 11 13.915c0 1.618 1.158 2.914 2.947 2.914h.035ZM20 36h5.57v-9.276c0-.496.035-.992.182-1.347.403-.992 1.32-2.02 2.858-2.02 2.015 0 2.821 1.524 2.821 3.757V36H37v-9.524C37 21.374 34.252 19 30.588 19c-3.004 0-4.323 1.665-5.056 2.799h.037v-2.41H20C20.073 20.95 20 36 20 36Z"
      fill="#D8D8D8"
      className={pathClassName}
    />
  </svg>
);

export default memo(LinkedinIcon);
