import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { ArrayElement, ExpandedArticleType } from '../../utils/blogTypes';
import Link from '../Link';
import Text from '../Text';
import './style.scss';

export interface AuthorBlockProps
  extends ArrayElement<ExpandedArticleType['author']> {
  className?: string;
}

const AuthorBlock: React.FC<AuthorBlockProps> = ({
  name,
  image: rawImage,
  title,
  className,
}) => {
  const image = getImage(rawImage.asset)!;

  const authorNameSlug = name
    .toLowerCase()
    .split(' ')
    .join('-');

  return (
    <Link
      href={`/blog/authors/${authorNameSlug}`}
      className={`author ${className}`}
    >
      <div className="author__avatar-box">
        <GatsbyImage image={image} alt={name} className="author__avatar" />
      </div>
      <div className="author__author-details">
        <Text type="p" color="dark" className="author-details__name">
          {name}
        </Text>
        <Text type="p" color="dark" className="author-details__title">
          {title}
        </Text>
      </div>
    </Link>
  );
};

export default React.memo(AuthorBlock);
