import * as React from 'react';
import { SVGProps, memo } from 'react';

interface MedalIconProps extends SVGProps<SVGSVGElement> {
  iconText?: string | number;
  textClassName?: string;
  textXOffset?: number;
}

const MedalIcon = ({
  iconText,
  textClassName,
  textXOffset,
  fill = '#29D5AC',
  ...props
}: MedalIconProps) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={41} cy={32} r={14} fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.614 19.733c-6.731 0-12.188 5.37-12.188 11.995 0 6.625 5.457 11.995 12.188 11.995 6.73 0 12.187-5.37 12.187-11.995 0-6.625-5.456-11.995-12.187-11.995ZM23.383 31.728c0-9.366 7.714-16.959 17.23-16.959 9.517 0 17.231 7.593 17.231 16.959s-7.714 16.958-17.23 16.958c-9.517 0-17.231-7.592-17.231-16.958Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.246 41.428c1.381-.18 2.649.775 2.832 2.134l2.542 18.86a2.467 2.467 0 0 1-1.115 2.402 2.556 2.556 0 0 1-2.682.053l-9.209-5.438-9.21 5.438c-.83.49-1.872.47-2.68-.053a2.467 2.467 0 0 1-1.116-2.401l2.543-18.84c.183-1.36 1.45-2.315 2.831-2.134 1.38.18 2.351 1.428 2.168 2.787l-1.854 13.736 6.02-3.555a2.556 2.556 0 0 1 2.595 0l6.022 3.556-1.855-13.758c-.183-1.36.788-2.607 2.168-2.787Z"
      fill={fill}
    />
    <text
      x={35 + (textXOffset ?? 0)}
      y={38}
      fill="#fff"
      className={textClassName}
    >
      {iconText}
    </text>
  </svg>
);

export default memo(MedalIcon);
