import * as React from 'react';
import { SVGProps, memo } from 'react';

const LampIcon = ({ fill = '#F8B91C', ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={65}
    height={65}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.43 46.525v2.698c0 2.072-1.693 3.776-3.75 3.776h-5.356c-1.8 0-3.75-1.38-3.75-4.402v-2.072H39.43ZM42.45 15.364c-3.514-2.87-8.142-3.993-12.663-3.022-5.678 1.187-10.284 5.849-11.463 11.568-1.2 5.87.986 11.696 5.657 15.257 1.264.95 2.142 2.417 2.442 4.122v.021c.043-.021.108-.021.15-.021H39.43c.043 0 .065 0 .107.021v-.021c.3-1.64 1.265-3.151 2.743-4.316C45.9 36.08 48 31.765 48 27.103c0-4.575-2.014-8.848-5.55-11.74Z"
      fill={fill}
    />
  </svg>
);

export default memo(LampIcon);
