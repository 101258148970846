import React from 'react';
import { CardIconType, CardType } from '../../utils/blogTypes';
import FaceIcon from '../icons/FaceIcon';
import MedalIcon from '../icons/MedalIcon';
import './style.scss';

interface CardBoxIconProps extends Pick<CardType, 'iconType' | 'iconColor'> {
  index: number;
}

const CardBoxIcon: React.FC<CardBoxIconProps> = ({
  index,
  iconType,
  iconColor,
}) => {
  switch (iconType) {
    case CardIconType.MEDAL:
      return (
        <MedalIcon
          iconText={index + 1}
          textClassName="medal__text"
          textXOffset={index === 0 ? 1 : 0}
          fill={iconColor}
        />
      );
    case CardIconType.SAD_FACE:
    case CardIconType.HAPPY_FACE:
      return (
        <FaceIcon
          isSmiling={iconType === CardIconType.HAPPY_FACE}
          fill={iconColor}
        />
      );
  }
};

export default React.memo(CardBoxIcon);
