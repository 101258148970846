import React, { useMemo } from 'react';
import { ArticleBoxProps } from '../ArticleBox';
import BlockQuoteBox from '../BlockQuoteBox';
import ButtonBox from '../ButtonBox';
import CallToActionBox from '../CallToActionBox';
import CaptionedImageBox from '../CaptionedImageBox';
import CardsBox from '../CardsBox';
import CheckliEmbedBox from '../CheckliEmbedBox';
import EmbeddedBox from '../EmbeddedBox';
import ExcoBox from '../ExcoBox';
import GoogleTrendsBox from '../GoogleTrendsBox';
import InfoBox from '../InfoBox';
import ReadAlsoBox from '../ReadAlsoBox';
import SnippetBox from '../SnippetBox';
import TableBox from '../TableBox';
import TextBlock from '../TextBlock';
import TradingViewBox from '../TradingViewBox';
import './style.scss';

enum ArticleBoxVariants {
  Text = 'textBlock',
  Quote = 'blockQuote',
  Image = 'captionedImage',
  CallToAction = 'callToActionBlock',
  Card = 'cardBlock',
  Info = 'infoBlock',
  ReadAlso = 'readAlsoBlock',
  Table = 'textTable',
  Embedded = 'embeddedBox',
  Button = 'buttonBox',
  Snippet = 'snippetBox',
  Exco = 'excoBox',
  Trends = 'googleTrendsBox',
  TradingViewEmbed = 'tradingViewEmbedBox',
  CheckliEmbed = 'checkliEmbedBox',
}

interface ArticleBoxData {
  BoxElement: React.ReactElement;
  boxClassName: string;
}

const ANCHOR_TEST_PATTERN = /\{#.*?\}/g;

const ArticleMainBox: React.FC<
  Pick<
    ArticleBoxProps,
    'className' | 'regularBox' | 'widenedBoxClassName' | 'onInteract'
  >
> = ({ className, regularBox: box, widenedBoxClassName, onInteract }) => {
  const marginedClassName = `margined-box ${className}`;

  const { BoxElement, boxClassName } = useMemo<ArticleBoxData>(() => {
    switch (box?._type) {
      case ArticleBoxVariants.Text:
        return {
          BoxElement: <TextBlock {...box} onInteract={onInteract} />,
          boxClassName: className ?? '',
        };
      case ArticleBoxVariants.Quote:
        return {
          BoxElement: <BlockQuoteBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.Image:
        return {
          BoxElement: <CaptionedImageBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.CallToAction:
        return {
          BoxElement: <CallToActionBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.Card:
        return {
          BoxElement: <CardsBox {...box} />,
          boxClassName: `margined-box ${widenedBoxClassName}`,
        };
      case ArticleBoxVariants.Info:
        return {
          BoxElement: <InfoBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.ReadAlso:
        return {
          BoxElement: <ReadAlsoBox {...box} />,
          boxClassName: `margined-box ${widenedBoxClassName}`,
        };
      case ArticleBoxVariants.Table:
        return {
          BoxElement: <TableBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.Embedded:
        return {
          BoxElement: <EmbeddedBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.Button:
        return {
          BoxElement: <ButtonBox {...box} />,
          boxClassName: `button-box ${marginedClassName}`,
        };
      case ArticleBoxVariants.Snippet:
        return {
          BoxElement: <SnippetBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.Exco:
        return {
          BoxElement: <ExcoBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.Trends:
        return {
          BoxElement: <GoogleTrendsBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.TradingViewEmbed:
        return {
          BoxElement: <TradingViewBox {...box} />,
          boxClassName: marginedClassName,
        };
      case ArticleBoxVariants.CheckliEmbed:
        return {
          BoxElement: <CheckliEmbedBox {...box} />,
          boxClassName: marginedClassName,
        };
      default:
        return { BoxElement: <div></div>, boxClassName: '' };
    }
  }, [box, className, marginedClassName, onInteract, widenedBoxClassName]);

  return box?._type === ArticleBoxVariants.Text &&
    ANCHOR_TEST_PATTERN.test(box.text) ? (
    <TextBlock {...box} onInteract={onInteract} className={className ?? ''} />
  ) : (
    <div className={boxClassName}>{BoxElement}</div>
  );
};

export default React.memo(ArticleMainBox);
