import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { BlockQuoteBoxType } from '../../utils/blogTypes';
import QuoteAvatar from '../QuoteAvatar';
import Text from '../Text';
import './style.scss';
import Markdown from '../Markdown';

interface BlockQuoteBoxProps extends BlockQuoteBoxType {
  className?: string;
}

const BlockQuoteBox: React.FC<BlockQuoteBoxProps> = ({
  className,
  body,
  author,
  image,
}) => {
  const Avatar = useMemo(() => {
    const gatsbyAvatar = getImage(image.asset)!;

    return <GatsbyImage image={gatsbyAvatar} alt={author ?? ''} />;
  }, [author, image.asset]);

  return (
    <div className={`quote-box ${className}`}>
      <div className="quote-box__avatar">
        <QuoteAvatar Image={Avatar} />
      </div>

      <div className="text-block">
        <Markdown className="text-block__body" source={body} />
        <Text type="p" color="dark" weight="bold" className="text-block__title">
          {author}
        </Text>
      </div>
    </div>
  );
};

export default React.memo(BlockQuoteBox);
