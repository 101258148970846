import React from 'react';
import useCurrentlyWatchedElementId from '../../hooks/useCurrentlyWatchedElementId';
import { TextBlockType } from '../../utils/blogTypes';
import Markdown from '../Markdown';
import './style.scss';

export const SELECTOR = 'h2[id]';

export type OnHeaderInteract = (id: string, isScrollingBack: boolean) => void;

export interface TextBlockProps extends TextBlockType {
  className?: string;
  onInteract?: OnHeaderInteract;
}

const TextBlock: React.FC<TextBlockProps> = ({
  text,
  className,
  onInteract,
}) => {
  const mdRef = useCurrentlyWatchedElementId(SELECTOR, onInteract);

  return (
    <Markdown source={text} className={`${className} text-box`} ref={mdRef} />
  );
};

export default React.memo(TextBlock);
