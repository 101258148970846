import React, { CSSProperties, useMemo } from 'react';
import { CallToActionBoxType } from '../../utils/blogTypes';
import ArrowFive from '../arrows/ArrowFive';
import ArrowFour from '../arrows/ArrowFour';
import ArrowOne from '../arrows/ArrowOne';
import ArrowThree from '../arrows/ArrowThree';
import ArrowTwo from '../arrows/ArrowTwo';
import Button from '../Button';
import Text from '../Text';
import './style.scss';

interface CallToActionBoxProps extends CallToActionBoxType {
  className?: string;
}

const CallToActionBox: React.FC<CallToActionBoxProps> = ({
  className,
  backgroundColor,
  contentColor,
  buttonText,
  buttonLink,
  body,
}) => {
  const containerStyle = useMemo<CSSProperties>(() => ({ backgroundColor }), [
    backgroundColor,
  ]);

  const buttonTextStyle = useMemo<CSSProperties>(
    () => ({ color: backgroundColor }),
    [backgroundColor]
  );

  const buttonStyle = useMemo<CSSProperties>(
    () => ({ backgroundColor: contentColor }),
    [contentColor]
  );

  const titleStyle = useMemo<CSSProperties>(() => ({ color: contentColor }), [
    contentColor,
  ]);

  return (
    <div style={containerStyle} className={`call-to-action-box ${className}`}>
      <Text
        style={titleStyle}
        type="p"
        color="light"
        weight="bold"
        className="call-to-action-box__title"
      >
        {body}
      </Text>

      <ArrowOne className="call-to-action-box__arrow-1 call-to-action-box__arrow" />
      <ArrowTwo className="call-to-action-box__arrow-2 call-to-action-box__arrow" />
      <ArrowThree className="call-to-action-box__arrow-3 call-to-action-box__arrow" />
      <ArrowFour className="call-to-action-box__arrow-4 call-to-action-box__arrow" />
      <ArrowFive className="call-to-action-box__arrow-5 call-to-action-box__arrow" />
      <Button
        style={buttonStyle}
        size="medium"
        color="light"
        href={buttonLink}
        className="call-to-action-box__button"
      >
        <Text style={buttonTextStyle} type="p" weight="bold" color="dark">
          {buttonText}
        </Text>
      </Button>
    </div>
  );
};

export default React.memo(CallToActionBox);
