import React, { useCallback, useMemo } from 'react';
import { TableBoxType, TableRow } from '../../utils/blogTypes';
import Markdown from '../Markdown';
import TableCell from '../TableCell';
import './style.scss';

interface TableBoxProps extends TableBoxType {
  className?: string;
}

const TableBox: React.FC<TableBoxProps> = ({ className, rows, headerText }) => {
  const renderRow = useCallback<(row: TableRow, index: number) => JSX.Element>(
    row => (
      <tr>
        {row.items.map(item => (
          <TableCell
            {...item}
            key={item._id}
            isHeader={row.isHeader ?? false}
          />
        ))}
      </tr>
    ),
    []
  );

  const Header = useMemo(
    () => headerText && <Markdown source={headerText} className="header" />,
    [headerText]
  );

  return (
    <div className={className}>
      {Header}
      <table>{rows.map(renderRow)}</table>
    </div>
  );
};

export default React.memo(TableBox);
