import { HeadProps, PageProps, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import useBoxScripts from '../../hooks/useBoxScripts';
import useHeaderInteractionHandler from '../../hooks/useHeaderInteractionHandler';
import usePairedBoxes, { PairedBoxType } from '../../hooks/usePairedBoxes';
import useTableOfContents from '../../hooks/useTableOfContents';
import { EXCO_SCRIPT, SITE_URL } from '../../theme/constants';
import {
  ArticleStructuredDataType,
  ExpandedArticleType,
  PreviewArticleType,
  SocialIconProps,
} from '../../utils/blogTypes';
import { getBoxesReadTime } from '../../utils/textHelpers';
import ArticleAuthors from '../ArticleAuthors';
import ArticleBox from '../ArticleBox';
import ArticleFeedbackBox from '../ArticleFeedbackBox';
import ArticleHeader from '../ArticleHeader';
import AuthorBlock, { AuthorBlockProps } from '../AuthorBlock';
import Breadcrumbs from '../Breadcrumbs';
import Button from '../Button';
import ContactForm from '../ContactForm';
import FixedTableOfContents from '../FixedTableOfContents';
import Footer from '../Footer';
import Navigation from '../Navigation';
import Questions from '../Questions';
import ReadAlsoArticles from '../ReadAlsoArticles';
import TableOfContents from '../TableOfContents';
import Text from '../Text';
import FacebookIcon from '../icons/FacebookIcon';
import LinkedinIcon from '../icons/LinkedinIcon';
import TwitterIcon from '../icons/TwitterIcon';
import Layout from '../layout';
import SEO from '../seo';
import './style.scss';
import { not } from 'ramda';

type SharingIconProps = {
  Icon: React.FC<SocialIconProps>;
  link: string;
};

const getSocialSharingData = (
  slug: string,
  siteUrl: string
): SharingIconProps[] => {
  const pageUrl = `${siteUrl}/blog/${slug}`;

  return [
    {
      Icon: FacebookIcon,
      link: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
    },
    {
      Icon: LinkedinIcon,
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`,
    },
    {
      Icon: TwitterIcon,
      link: `https://twitter.com/intent/tweet?url=${pageUrl}`,
    },
  ];
};

type DataProps = {
  sanityArticle: ExpandedArticleType;
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
  allSanityArticle: { nodes: PreviewArticleType[] };
};

const Article = ({
  data: {
    sanityArticle,
    site: {
      siteMetadata: { siteUrl },
    },
    allSanityArticle,
  },
}: PageProps<DataProps>) => {
  const renderSharingIcons = useCallback<
    (data: SharingIconProps) => JSX.Element
  >(({ link, Icon }) => {
    return (
      <a
        className={'sharing__icon'}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <Icon
          parentClassName="sharing-icon"
          pathClassName="sharing-icon__path"
          circleClassName="sharing-icon__circle"
        />
      </a>
    );
  }, []);

  const pairedBoxes = usePairedBoxes(sanityArticle.boxes);

  const contents = useTableOfContents(sanityArticle.boxes);

  const readTime = useMemo(
    () => getBoxesReadTime(sanityArticle.boxes),
    [sanityArticle.boxes]
  );

  const { onInteractWithHeaders, currentIndex } =
    useHeaderInteractionHandler(contents);

  const renderBox = useCallback<(pair: PairedBoxType) => JSX.Element>(
    (box) => (
      <ArticleBox
        {...box}
        className="box_inner"
        widenedBoxClassName="box_inner-widened"
        onInteract={onInteractWithHeaders}
      />
    ),
    [onInteractWithHeaders]
  );

  const socialSharingData = useMemo(
    () => getSocialSharingData(sanityArticle.slug.current, SITE_URL ?? ''),
    [sanityArticle]
  );

  const { t } = useTranslation();

  const setDataLayerEvent = useCallback(
    () => window?.dataLayer?.push?.({ event: 'blog_form_success' }),
    []
  );

  const renderAuthor = useCallback<(author: AuthorBlockProps) => JSX.Element>(
    (author) => <AuthorBlock className="authors__author-item" {...author} />,
    []
  );

  const FAQ = useMemo(() => {
    if (sanityArticle.faqs?.length < 1) {
      return;
    }

    return (
      <div className="inner-boxes">
        <div className="box_inner">
          <Questions
            title={t('article_faq_block_title')}
            subTitle={t('article_faq_block_description')}
            questions={sanityArticle.faqs}
            multilane={false}
            className="article-faq"
          />
        </div>
      </div>
    );
  }, [sanityArticle.faqs, t]);

  const [contentsExpanded, setContentsExpanded] = useState(false);

  const toggleContentsExpanded = useCallback(
    () => setContentsExpanded(not),
    []
  );

  const collapseContents = useCallback(() => setContentsExpanded(false), []);

  return (
    <Layout>
      <Navigation
        headerBackgroundColor="light"
        shadowed={currentIndex >= 0 ? false : true}
      />
      <FixedTableOfContents
        contents={contents}
        currentItemIndex={currentIndex}
        isExpanded={contentsExpanded}
        toggleExpanded={toggleContentsExpanded}
      />

      <ArticleHeader
        className="article-page__header"
        siteUrl={siteUrl}
        tags={sanityArticle.tags}
        title={sanityArticle.title}
        createdAt={sanityArticle._createdAt}
        updatedAt={sanityArticle._updatedAt}
        readTime={readTime}
      />

      <Breadcrumbs
        className="article-page__navigation"
        currentPageTitle={sanityArticle.title}
      />

      <div onClick={collapseContents}>
        <div>
          <div className="sharing">
            <div className="sharing-text-wrapper">
              <Text
                type="p"
                color="alternative2"
                weight="bold"
                className="sharing-text-wrapper__text"
              >
                {t('share')}
              </Text>
            </div>
            {socialSharingData.map(renderSharingIcons)}
            <div className="sharing__placeholder" />
          </div>

          <div className="inner-boxes">
            <div className="box_inner authors">
              {sanityArticle.author.map(renderAuthor)}
            </div>

            <TableOfContents
              contents={contents}
              className="box_inner page__table-of-contents"
            />

            {pairedBoxes.map(renderBox)}
          </div>
        </div>

        <Button
          color="secondary"
          size="big"
          className="contact-us-button page__contact-us-button"
          href="/contact-us/"
        >
          <Text
            weight="bold"
            color="light"
            type="p"
            className="contact-us-button__text"
          >
            {sanityArticle.contactUsButtonText}
          </Text>
        </Button>

        <div className="dividing-line dividing-line_halved" />
        <ArticleFeedbackBox articleId={sanityArticle.id} />

        {FAQ}

        <div className="inner-boxes article-authors">
          <ArticleAuthors className="box_inner" author={sanityArticle.author} />
        </div>

        <div className="dividing-line" />
        <ReadAlsoArticles
          articles={
            sanityArticle.readAlsoArticles.length
              ? sanityArticle.readAlsoArticles
              : allSanityArticle.nodes
          }
          className="box read-also-articles"
        />

        <ContactForm
          className="box contact-form"
          titleTagType="p"
          onSuccess={setDataLayerEvent}
        />
        <Footer noFooterCard />
      </div>
    </Layout>
  );
};

export default React.memo(Article);

export const query = graphql`
  query (
    $id: String!
    $language: String!
    $articlesFilter: SanityArticleFilterInput
    $limit: Int
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSanityArticle(
      filter: $articlesFilter
      limit: $limit
      sort: { order: DESC, fields: _createdAt }
      skip: 1
    ) {
      nodes {
        ...ListArticle
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(
                height: 210
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          altText
          caption
        }
      }
    }
    sanityArticle(id: { eq: $id }) {
      ...ListArticle
      _createdAt
      _updatedAt
      description
      metaTitle
      author {
        name
        title
        description
        linkedin
        facebook
        twitter
        image {
          asset {
            gatsbyImageData(
              height: 200
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      contactUsButtonText
      contactUsButtonColor
      readAlsoArticles {
        ...ListArticle
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(
                height: 210
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          altText
          caption
        }
      }
      headerImage {
        mainImage {
          asset {
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        altText
        caption
      }
      faqs {
        question
        answer
      }
      boxes {
        ...BoxFragment
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data: { sanityArticle } }: HeadProps<DataProps>) => {
  const {
    isCheckliPresent,
    isExcoPresent,
    isTrendingViewPresent,
    googleTrendsScriptUrls,
  } = useBoxScripts(sanityArticle.boxes);

  const renderTrendScript = useCallback<(url: string) => JSX.Element>((url) => {
    return <script async type="text/javascript" src={url}></script>;
  }, []);

  const headerImageUrl =
    sanityArticle?.headerImage?.mainImage?.asset?.gatsbyImageData?.images
      ?.fallback?.src;

  const structuredData = useMemo<ArticleStructuredDataType>(
    () => ({
      title: sanityArticle.title,
      authorName: sanityArticle.author[0].name,
      imageUrl: headerImageUrl,
      slug: sanityArticle.slug.current,
      datePublished: sanityArticle._createdAt,
      dateModified: sanityArticle._updatedAt,
      faqs: sanityArticle.faqs,
    }),
    [
      headerImageUrl,
      sanityArticle._createdAt,
      sanityArticle._updatedAt,
      sanityArticle.author,
      sanityArticle.faqs,
      sanityArticle.slug,
      sanityArticle.title,
    ]
  );

  return (
    <SEO
      title={sanityArticle.metaTitle}
      description={sanityArticle.description}
      articleStructuredData={structuredData}
    >
      {isExcoPresent && <script async>{EXCO_SCRIPT}</script>}
      {googleTrendsScriptUrls.map(renderTrendScript)}
      {isTrendingViewPresent && (
        <script async src="https://s3.tradingview.com/tv.js" />
      )}
      {isCheckliPresent && (
        <script defer src="https://checkli.com/js/checkli-embed.js" />
      )}
      <meta property="og:image" content={headerImageUrl} />
    </SEO>
  );
};
