import React, { useEffect } from 'react';
import { EmbeddedBoxType } from '../../utils/blogTypes';
import lazyframe from 'lazyframe';
import './style.scss';

interface EmbeddedBoxProps extends EmbeddedBoxType {
  className?: string;
}

const EmbeddedBox: React.FC<EmbeddedBoxProps> = ({
  link,
  height,
  width,
  allow,
  style,
  className,
  id,
}) => {
  const frameId = `lazyframe-${id}`;
  const isYoutube = link.includes('youtube');

  useEffect(() => {
    if (isYoutube) {
      lazyframe(`.${frameId}`, {
        lazyload: false,
        debounce: 200,
      });
    }
  }, [frameId, isYoutube, link]);

  if (isYoutube) {
    return (
      <div
        data-src={link}
        data-vendor="youtube"
        className={`${className} ${frameId} lazyframe`}
      />
    );
  }

  return (
    <iframe
      title="="
      src={link}
      height={height}
      width="100%"
      allow={allow}
      className={className}
    />
  );
};

export default React.memo(EmbeddedBox);
