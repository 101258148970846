import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import truncateMarkdown from 'markdown-truncate';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthorBlockProps } from '../AuthorBlock';
import { getAuthorSocialData, renderAuthorSocialIcons } from '../AuthorPage';
import Link from '../Link';
import Markdown from '../Markdown';
import Text from '../Text';
import ArrowRightOutlined from '../icons/ArrowRightOutlined';
import './style.scss';

const AuthorBlockExpanded: React.FC<AuthorBlockProps> = ({
  name,
  image: rawImage,
  title,
  description,
  linkedin,
  twitter,
  facebook,
  className = '',
}) => {
  const { t } = useTranslation();

  const image = getImage(rawImage.asset)!;

  const authorSocialData = useMemo(
    () => getAuthorSocialData({ facebook, twitter, linkedin }),
    [facebook, linkedin, twitter]
  );

  const truncatedMarkdown = useMemo(
    () =>
      truncateMarkdown(description ?? '', {
        limit: 100,
        ellipsis: true,
      }),
    [description]
  );

  const authorNameSlug = name.toLowerCase().split(' ').join('-');

  return (
    <>
      <Link
        className={`large-expanded-author ${className}`}
        href={`/blog/authors/${authorNameSlug}`}
      >
        <div className="large-expanded-author__avatar-box">
          <GatsbyImage
            image={image}
            alt={name}
            className="large-expanded-author__avatar"
          />
        </div>

        <div className="large-expanded-author__details">
          <div className="large-expanded-author__details-body">
            <Text
              type="p"
              weight="demi-bold"
              color="dark"
              className="large-expanded-author__details-name"
            >
              {name}
            </Text>
            <Text
              type="p"
              color="dark"
              className="large-expanded-author__details-title"
            >
              {title}
            </Text>

            <Markdown
              source={truncatedMarkdown}
              className={'large-expanded-author__details-description'}
            />
          </div>

          <div className="large-expanded-author__details-footer">
            <div className="large-expanded-author__details-socials">
              {authorSocialData?.map(renderAuthorSocialIcons)}
            </div>

            <Link
              href={`/blog/authors/${authorNameSlug}`}
              className={'large-expanded-author__details-author-page'}
            >
              <Text
                type="p"
                color="dark"
                weight="bold"
                className="large-expanded-author__author-page-title"
              >
                {t('author_page')}
              </Text>

              <ArrowRightOutlined />
            </Link>
          </div>
        </div>
      </Link>

      <Link
        className={`small-expanded-author ${className}`}
        href={`/blog/authors/${authorNameSlug}`}
      >
        <div className="small-expanded-author__header">
          <div className="small-expanded-author__avatar-box">
            <GatsbyImage
              image={image}
              alt={name}
              className="small-expanded-author__avatar"
            />
          </div>

          <div className="small-expanded-author__bio">
            <Text
              type="p"
              weight="demi-bold"
              color="dark"
              className="small-expanded-author__name"
            >
              {name}
            </Text>
            <Text
              type="p"
              color="dark"
              className="small-expanded-author__job-title"
            >
              {title}
            </Text>
          </div>
        </div>

        {description && (
          <div className="small-expanded-author__body">
            <Markdown
              source={truncatedMarkdown}
              className={'small-expanded-author__description'}
            />
          </div>
        )}

        <div className="small-expanded-author__footer">
          <div className="small-expanded-author__socials">
            {authorSocialData?.map(renderAuthorSocialIcons)}
          </div>

          <Link
            href={`/blog/authors/${authorNameSlug}`}
            className={'small-expanded-author__author-page'}
          >
            <Text
              type="p"
              color="dark"
              weight="bold"
              className="small-expanded-author__author-page-title"
            >
              {t('author_page')}
            </Text>

            <ArrowRightOutlined />
          </Link>
        </div>
      </Link>
    </>
  );
};

export default React.memo(AuthorBlockExpanded);
