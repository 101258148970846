import React from 'react';
import { CheckliEmbedBoxType } from '../../utils/blogTypes';

interface CheckliEmebedBoxProps extends CheckliEmbedBoxType {
  className?: string;
}

const CheckliEmebedBox: React.FC<CheckliEmebedBoxProps> = ({
  className,
  embedId,
  embedUrl,
}) => {
  return (
    <div id={embedId} className={`checkli-embed ${className}`} url={embedUrl} />
  );
};

export default React.memo(CheckliEmebedBox);
