import * as React from 'react';
import { SVGProps, memo } from 'react';

const Arrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={65}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.983 36.2a.8.8 0 1 0 .034 1.6l-.034-1.6ZM.434 8.41a.8.8 0 0 0 0 1.13l5.092 5.092a.8.8 0 0 0 1.13-1.131L2.132 8.975 6.657 4.45a.8.8 0 1 0-1.132-1.132L.435 8.41ZM42.017 37.8c6.16-.128 9.96-2.129 11.919-5.137 1.938-2.976 1.932-6.727.957-10.074-.975-3.348-2.963-6.457-5.229-8.227-1.134-.886-2.397-1.483-3.681-1.533-1.322-.053-2.564.477-3.585 1.644l1.204 1.054c.73-.833 1.518-1.131 2.318-1.1.836.033 1.784.433 2.76 1.196 1.952 1.526 3.777 4.324 4.677 7.413.9 3.09.831 6.308-.762 8.754-1.572 2.414-4.772 4.288-10.612 4.41l.034 1.6Zm.38-23.327c-.992 1.136-1.422 2.363-1.3 3.593.118 1.208.757 2.298 1.664 3.21 1.802 1.81 4.834 3.098 8.025 3.456 3.202.36 6.708-.202 9.433-2.275C62.973 20.36 64.8 16.82 64.8 11.499h-1.6c0 4.93-1.673 7.952-3.95 9.684-2.306 1.755-5.363 2.287-8.286 1.96-2.934-.33-5.59-1.51-7.069-2.996-.734-.738-1.134-1.503-1.206-2.238-.07-.714.157-1.518.913-2.382l-1.204-1.054ZM64.8 11.5c0-2.784-.534-5.001-1.598-6.706-1.072-1.718-2.639-2.843-4.563-3.516-3.78-1.32-9.033-.92-15.024.107-6 1.029-13.021 2.746-20.27 4.176C16.05 7 8.422 8.175 1 8.175v1.6c7.578 0 15.326-1.198 22.655-2.645 7.376-1.456 14.23-3.14 20.23-4.169 6.009-1.03 10.88-1.342 14.226-.173 1.639.572 2.885 1.492 3.734 2.852.857 1.374 1.355 3.268 1.355 5.86h1.6Z"
      fill="#F8B91C"
    />
  </svg>
);

export default memo(Arrow);
