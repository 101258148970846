import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback } from 'react';
import usePersistentState from '../../hooks/usePersistentState';
import FingerDownIcon from '../icons/FingerDownIcon';
import FingerUpIcon from '../icons/FingerUpIcon';
import Text from '../Text';
import './style.scss';

const FEEDBACK_STATE_KEY = '@FEEDBACK_STATE_KEY_';

enum FeedbackState {
  LIKED = 'LIKED',
  DISLIKED = 'DISLIKED',
  NONE = 'NONE',
}

type ArticleFeedbackBoxProps = {
  articleId: string;
};

const ArticleFeedbackBox: React.FC<ArticleFeedbackBoxProps> = ({
  articleId,
}) => {
  const { t } = useTranslation();

  const [feedbackState, setFeedbackState] = usePersistentState(
    FeedbackState.NONE,
    `${FEEDBACK_STATE_KEY}${articleId}`
  );

  const onLike = useCallback(() => {
    setFeedbackState(FeedbackState.LIKED);
  }, [setFeedbackState]);

  const onDisike = useCallback(() => {
    setFeedbackState(FeedbackState.DISLIKED);
  }, [setFeedbackState]);

  return (
    <div className="feedback">
      <Text type="p" color="dark" weight="bold" className="feedback__title h4">
        {t('article_feedback_box_title')}
      </Text>

      <div className="feedback__button-container">
        <button
          className="button feedback__button"
          onClick={onLike}
          aria-label="like"
        >
          <FingerUpIcon isSelected={feedbackState === FeedbackState.LIKED} />
        </button>

        <button
          className="button feedback__button"
          onClick={onDisike}
          aria-label="dislike"
        >
          <FingerDownIcon
            isSelected={feedbackState === FeedbackState.DISLIKED}
          />
        </button>
      </div>
    </div>
  );
};

export default React.memo(ArticleFeedbackBox);
