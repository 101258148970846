import * as React from 'react';
import { SVGProps, memo } from 'react';

const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={41}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity={0.1} cx={20} cy={20.002} r={20} fill="#F8B91C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.925 16.347a4.306 4.306 0 0 1 3.073-1.295c1.155 0 2.26.468 3.073 1.295a4.44 4.44 0 0 1 1.266 3.11c0 2.01.422 3.247.8 3.956.191.356.375.586.5.72a1.632 1.632 0 0 0 .159.15.77.77 0 0 1-.444 1.398H14.644a.77.77 0 0 1-.444-1.397 1.648 1.648 0 0 0 .16-.151c.124-.134.308-.364.498-.72.38-.709.802-1.945.802-3.956a4.44 4.44 0 0 1 1.265-3.11Zm-2.714 7.93Zm2.002-.134h7.57c0-.002 0-.003-.002-.004-.512-.958-.983-2.45-.983-4.682 0-.765-.298-1.495-.825-2.032a2.767 2.767 0 0 0-1.975-.835c-.738 0-1.449.299-1.975.835a2.902 2.902 0 0 0-.825 2.032c0 2.233-.47 3.724-.983 4.682l-.002.004ZM18.588 26.668a.77.77 0 0 1 1.05.288.432.432 0 0 0 .155.16.404.404 0 0 0 .41 0 .432.432 0 0 0 .155-.16.77.77 0 0 1 1.337.761c-.17.3-.416.55-.714.725a1.942 1.942 0 0 1-1.966 0 1.97 1.97 0 0 1-.715-.725.77.77 0 0 1 .288-1.049Z"
      fill="#F8B91C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.997 14.95c.341 0 .665-.29.665-.705a.686.686 0 0 0-.665-.705.686.686 0 0 0-.665.705c0 .416.324.705.665.705Zm0 1.477c1.183 0 2.142-.977 2.142-2.182s-.96-2.181-2.142-2.181c-1.183 0-2.142.976-2.142 2.181s.96 2.182 2.142 2.182Z"
      fill="#F8B91C"
    />
  </svg>
);

export default memo(BellIcon);
