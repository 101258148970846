import { graphql } from 'gatsby';
import { isEmpty } from 'ramda';
import React, { useCallback } from 'react';
import { PairedBoxType } from '../../hooks/usePairedBoxes';
import { SideNoteType } from '../../utils/blogTypes';
import ArticleMainBox from '../ArticleMainBox';
import SideNote from '../SideNote';
import { TextBlockProps } from '../TextBlock';
import './style.scss';

export type ArticleBoxProps = {
  className?: string;
  widenedBoxClassName?: string;
} & PairedBoxType &
  Partial<Pick<TextBlockProps, 'onInteract'>>;

const ArticleBox: React.FC<ArticleBoxProps> = ({
  regularBox,
  className,
  sideNotes,
  widenedBoxClassName,
  onInteract,
}) => {
  const renderSideNote = useCallback<(note: SideNoteType) => JSX.Element>(
    (note) => <SideNote {...note} className={`paired-box__note`} />,
    []
  );

  return isEmpty(sideNotes ?? []) ? (
    <ArticleMainBox
      onInteract={onInteract}
      className={className}
      regularBox={regularBox}
      widenedBoxClassName={widenedBoxClassName}
    />
  ) : (
    <div className={'paired-box'}>
      <ArticleMainBox
        onInteract={onInteract}
        regularBox={regularBox}
        className={`paired-box__main`}
        widenedBoxClassName={widenedBoxClassName}
      />
      <div className="box-notes">{sideNotes?.map(renderSideNote)}</div>
    </div>
  );
};

export const articleBoxFragment = graphql`
  fragment BoxFragment on SanityBlockQuoteOrButtonBoxOrCallToActionBlockOrCaptionedImageOrCardBlockOrCheckliEmbedBoxOrEmbeddedBoxOrExcoBoxOrGoogleTrendsBoxOrInfoBlockOrReadAlsoBlockOrSideNoteOrSnippetBoxOrTextBlockOrTextTableOrTradingViewEmbedBox {
    __typename
    ... on SanityTextBlock {
      text
      _type
    }
    ... on SanityBlockQuote {
      author
      image {
        asset {
          gatsbyImageData(
            height: 82
            width: 82
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      body
      _type
    }
    ... on SanityInfoBlock {
      body
      outlineColor
      _type
    }
    ... on SanityCallToActionBlock {
      body
      buttonText
      backgroundColor
      contentColor
      buttonLink
      _type
    }
    ... on SanityReadAlsoBlock {
      title
      body
      link
      backgroundColor
      _type
    }
    ... on SanityTextTable {
      headerText
      rows {
        _type
        id
        isHeader
        items {
          _type
          _id
          body
          rowspan
          colspan
          backgroundColor
        }
      }
      _type
    }
    ... on SanityCaptionedImage {
      _type
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caption
      altText
      imageLink
      imageHeight
      imageWidth
    }
    ... on SanityCardBlock {
      _type
      cardsWithMedals {
        title
        body
        iconType
        iconColor
      }
    }
    ... on SanitySideNote {
      _type
      body
      buttonText
      buttonLink
    }
    ... on SanityEmbeddedBox {
      id
      _type
      link
      height
      width
      style
      allow
    }
    ... on SanityButtonBox {
      backgroundColor
      contentColor
      text
      link
      _type
    }
    ... on SanitySnippetBox {
      _type
      body
      language
    }
    ... on SanityExcoBox {
      _type
      excoId
    }
    ... on SanityGoogleTrendsBox {
      _type
      options
      scriptUrl
      keywords
    }
    ... on SanityTradingViewEmbedBox {
      _type
      widgetOptions
      embedId
      chartUrl
      chartName
    }
    ... on SanityCheckliEmbedBox {
      _type
      embedId
      embedUrl
    }
  }
`;

export default React.memo(ArticleBox);
