import * as React from 'react';
import { SVGProps, memo } from 'react';

interface FingerUpIconProps extends SVGProps<SVGSVGElement> {
  isSelected?: boolean;
}

const FingerUpIcon = ({ isSelected = false, ...props }: FingerUpIconProps) => (
  <svg
    width={47}
    height={47}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.708 21.541v15.667a1.958 1.958 0 0 1-1.958 1.958H7.833a1.959 1.959 0 0 1-1.958-1.958V23.5a1.958 1.958 0 0 1 1.958-1.959h5.875Zm0 0a7.833 7.833 0 0 0 7.834-7.833V11.75a3.917 3.917 0 1 1 7.833 0v9.791h5.875a3.917 3.917 0 0 1 3.917 3.917l-1.959 9.792c-.281 1.201-.816 2.233-1.522 2.94-.706.706-1.547 1.049-2.394.976H19.583a5.875 5.875 0 0 1-5.875-5.875"
      stroke={isSelected ? '#29D5AC' : 'rgba(19, 23, 30, 0.6)'}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(FingerUpIcon);
