import React from 'react';
import { SideNoteType } from '../../utils/blogTypes';
import BellIcon from '../icons/BellIcon';
import Link from '../Link';
import Markdown from '../Markdown';
import Arrow from './Arrow';
import './style.scss';

interface SideNoteProps extends SideNoteType {
  className?: string;
}

const SideNote: React.FC<SideNoteProps> = ({
  body,
  buttonLink,
  buttonText,
  className,
}) => {
  return (
    <div className={`note ${className}`}>
      <Arrow className="note__arrow" />
      <BellIcon className="note__icon" />
      <div className="note__note-body">
        <Markdown source={body} className={'note-body__text'} />
        <Link className="note-body__button" href={buttonLink}>
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default React.memo(SideNote);
