import * as React from 'react';
import { memo } from 'react';
import { SocialIconProps } from '../../utils/blogTypes';

const TwitterIcon: React.FC<SocialIconProps> = ({
  pathClassName,
  circleClassName,
  parentClassName,
  ...props
}) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={parentClassName}
    {...props}
  >
    <circle
      cx={24}
      cy={24}
      r={23}
      fill="#fff"
      stroke="#D8D8D8"
      strokeWidth={2}
      className={circleClassName}
    />
    <path
      className={pathClassName}
      fill="#D8D8D8"
      id="svg_1"
      d="m36,16.557c-0.883,0.392 -1.832,0.656 -2.828,0.775c1.017,-0.609 1.798,-1.574 2.165,-2.724c-0.951,0.564 -2.005,0.974 -3.127,1.195c-0.897,-0.957 -2.178,-1.555 -3.594,-1.555c-3.179,0 -5.515,2.966 -4.797,6.045c-4.091,-0.205 -7.719,-2.165 -10.148,-5.144c-1.29,2.213 -0.669,5.108 1.523,6.574c-0.806,-0.026 -1.566,-0.247 -2.229,-0.616c-0.054,2.281 1.581,4.415 3.949,4.89c-0.693,0.188 -1.452,0.232 -2.224,0.084c0.626,1.956 2.444,3.379 4.6,3.419c-2.07,1.623 -4.678,2.348 -7.29,2.04c2.179,1.397 4.768,2.212 7.548,2.212c9.142,0 14.307,-7.721 13.995,-14.646c0.962,-0.695 1.797,-1.562 2.457,-2.549z"
    />
  </svg>
);

export default memo(TwitterIcon);
