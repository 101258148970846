import * as React from 'react';
import { SVGProps, memo } from 'react';

const ArrowFour = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={86}
    height={81}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.7} fillRule="evenodd" clipRule="evenodd" fill="#fff">
      <path d="M83.414 77.44C76.563 38.119 40.611 19.914 3.51 16.568a1.161 1.161 0 0 1 .209-2.31C41.859 17.692 78.656 36.618 85.7 77.043a1.16 1.16 0 1 1-2.286.397Z" />
      <path d="M4.312 15.474c1.678 1.158 3.8 2.587 4.035 2.757 6.396 4.586 11.738 9.92 16.486 16.217a1.162 1.162 0 0 1-1.854 1.398c-4.601-6.108-9.78-11.282-15.986-15.73-.361-.26-5.132-3.47-6.048-4.192-.378-.294-.5-.578-.522-.648a1.121 1.121 0 0 1 .026-.87c.108-.24.382-.593.927-.784.572-.197 1.851-.315 2.172-.37 4.835-.853 10.306-1.384 15.258-2.902 4.722-1.448 8.978-3.797 11.624-8.341a1.162 1.162 0 0 1 2.006 1.169c-2.96 5.08-7.671 7.772-12.948 9.39-4.923 1.509-10.343 2.074-15.176 2.906Z" />
    </g>
  </svg>
);

export default memo(ArrowFour);
