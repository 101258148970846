import { useMemo } from 'react';
import { ArticleBoxType } from '../utils/blogTypes';

const useBoxScripts = (boxes: ArticleBoxType[]) => {
  const isExcoPresent = useMemo(
    () => boxes.some(box => box._type === 'excoBox'),
    [boxes]
  );

  const isTrendingViewPresent = useMemo(
    () => boxes.some(box => box._type === 'tradingViewEmbedBox'),
    [boxes]
  );

  const isCheckliPresent = useMemo(
    () => boxes.some(box => box._type === 'checkliEmbedBox'),
    [boxes]
  );

  const googleTrendsScriptUrls = useMemo(
    () =>
      boxes.reduce<string[]>((acc, current) => {
        if (
          current._type === 'googleTrendsBox' &&
          !acc.includes(current.scriptUrl)
        ) {
          return [...acc, current.scriptUrl];
        }
        return acc;
      }, []),
    [boxes]
  );

  return {
    isExcoPresent,
    isCheckliPresent,
    isTrendingViewPresent,
    googleTrendsScriptUrls,
  };
};

export default useBoxScripts;
