import React, { useCallback } from 'react';
import { ExpandedArticleType } from '../../utils/blogTypes';
import AuthorBlockExpanded from '../AuthorBlockExpanded';
import { AuthorBlockProps } from '../AuthorBlock';
import Text from '../Text';
import { useTranslation } from 'react-i18next';
import './style.scss';

type ArticleAuthorsProps = Pick<ExpandedArticleType, 'author'> & {
  className?: string;
};

const ArticleAuthors: React.FC<ArticleAuthorsProps> = ({
  author: authors,
  className,
}) => {
  const { t } = useTranslation();

  const renderAuthor = useCallback<
    (author: AuthorBlockProps, index: number) => JSX.Element
  >(
    (author, index) => (
      <>
        {index !== 0 ? <div className="dividing-line" /> : null}

        <AuthorBlockExpanded {...author} />
      </>
    ),
    []
  );

  return (
    <div className={className}>
      <Text
        type="p"
        color="dark"
        weight="bold"
        className="article-authors-title h4"
      >
        {t('about_author')}
      </Text>
      <div>{authors.map(renderAuthor)}</div>
    </div>
  );
};

export default React.memo(ArticleAuthors);
