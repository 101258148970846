import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { PreviewArticleType } from '../../utils/blogTypes';
import Text from '../Text';
import './style.scss';
import MoreArticles from '../MoreArticles';

type ReadAlsoArticlesProps = {
  articles: PreviewArticleType[];
  className?: string;
};

const ReadAlsoArticles: React.FC<ReadAlsoArticlesProps> = ({
  articles,
  className,
}) => {
  const { t } = useTranslation();

  return articles.length ? (
    <>
      <Text type="p" weight="bold" color="dark" className="read-also-title">
        {t('read_also')}
      </Text>
      <MoreArticles articles={articles} className={`${className}`} />
    </>
  ) : (
    <></>
  );
};

export default React.memo(ReadAlsoArticles);
