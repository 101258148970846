import * as React from 'react';
import { SVGProps, memo } from 'react';

const ArrowTwo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={126}
    height={105}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.7} fillRule="evenodd" clipRule="evenodd" fill="#fff">
      <path d="M58.665 80.093c-.263.098-.5.204-.686.316-2.137 1.303-4.017 2.928-6.038 4.392-5.719 4.143-10.137 9.979-12.376 16.665a1.231 1.231 0 0 0 2.334.781c2.077-6.203 6.182-11.612 11.486-15.455 1.97-1.426 3.796-3.01 5.878-4.282.311-.19.852-.306 1.33-.455.52-.165.993-.355 1.297-.567.504-.349.736-.794.775-1.25a1.626 1.626 0 0 0-.303-1.086c-.11-.16-.334-.394-.74-.601-.616-.318-2.08-.785-4-1.4-3.147-1.003-7.588-2.442-11.314-4.377-2.337-1.21-4.395-2.603-5.578-4.237a1.232 1.232 0 1 0-1.996 1.444c1.373 1.897 3.722 3.57 6.44 4.98 3.855 1.998 8.447 3.497 11.7 4.536.626.2 1.246.407 1.79.596Z" />
      <path d="M55.694 79.017c-9.593 7.59-18.314 8.607-24.693 5.38-9.6-4.856-13.644-19.023-7.374-33.58C39.32 14.388 85.408 1.394 121.205 4.75a1.232 1.232 0 0 0 .229-2.453C84.684-1.147 37.476 12.44 21.365 49.841c-6.881 15.975-2.011 31.424 8.524 36.753 7.046 3.562 16.738 2.736 27.331-5.646a1.234 1.234 0 0 0 .203-1.729 1.229 1.229 0 0 0-1.729-.202Z" />
    </g>
  </svg>
);

export default memo(ArrowTwo);
