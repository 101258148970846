import { useCallback, useState } from 'react';

const isBrowser = () => typeof window !== 'undefined';

const getState = (key: string) => {
  return JSON.parse(
    (isBrowser() ? window?.localStorage.getItem(key) : undefined) ?? '{}'
  ).state;
};

const updateState = (key: string, newState: any) =>
  window?.localStorage.setItem(key, JSON.stringify({ state: newState }));

const usePersistentState = <T>(initialState: T, storageKey: string) => {
  const [state, setState] = useState(getState(storageKey) || initialState);

  const onUpdateState = useCallback<(newState: T) => void>(
    newState => {
      setState(newState);
      updateState(storageKey, newState);
    },
    [storageKey]
  );

  return [state, onUpdateState];
};

export default usePersistentState;
