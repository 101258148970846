/* eslint-disable react-hooks/exhaustive-deps */
import { concat } from 'ramda';
import { useMemo } from 'react';
import { ArticleBoxType } from '../utils/blogTypes';

export type ContentsItem = {
  slug: string;
  title: string;
};

const contentsRegex = /^##(.*){#(.*)}/gm;

const START_ACCUMULATOR: ContentsItem[] = [];

const useTableOfContents = (boxes: ArticleBoxType[]) => {
  const contentItems = useMemo(
    () =>
      boxes.reduce((accumulator, current) => {
        if (current._type !== 'textBlock') {
          return accumulator;
        }

        const headingMatchIterator = current.text.matchAll(contentsRegex);

        const result: ContentsItem[] = Array.from(
          headingMatchIterator,
          item => ({ slug: item[2].trim(), title: item[1].trim() })
        );

        return concat(accumulator, result);
      }, START_ACCUMULATOR),
    []
  );

  return contentItems;
};

export default useTableOfContents;
