import * as React from 'react';

const ArrowRightOutlined = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#13171E"
      fillRule="evenodd"
      d="M9.41 6.578a.833.833 0 0 1 1.18 0l3.332 3.333a.833.833 0 0 1 0 1.178l-3.333 3.334a.833.833 0 0 1-1.178-1.179l1.91-1.91H6.25a.833.833 0 1 1 0-1.667h5.071l-1.91-1.91a.833.833 0 0 1 0-1.18Z"
      clipRule="evenodd"
    />
    <path
      fill="#D0D1D2"
      fillRule="evenodd"
      d="M10 18.833a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666Zm0 1.667c5.523 0 10-4.477 10-10S15.523.5 10 .5 0 4.977 0 10.5s4.477 10 10 10Z"
      clipRule="evenodd"
    />
  </svg>
);

export default React.memo(ArrowRightOutlined);
