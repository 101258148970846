import { useEffect, useMemo, useRef } from 'react';
import { TextBlockProps } from '../components/TextBlock';

const DEFAULT_ROOT_MARGIN = '0px 0px -79.9% 0px';

const useCurrentlyWatchedElementId = (
  selector: string,
  onInteract?: Pick<TextBlockProps, 'onInteract'>['onInteract']
) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const observer = useMemo(
    () =>
      typeof IntersectionObserver !== 'undefined'
        ? new IntersectionObserver(
            entries => {
              entries.reverse().forEach(entry => {
                const isScrollingBack =
                  !entry.isIntersecting && entry.boundingClientRect.y > 0;

                if (isScrollingBack || entry.isIntersecting) {
                  onInteract?.(entry.target.id, isScrollingBack);
                }
              });
            },
            { rootMargin: DEFAULT_ROOT_MARGIN }
          )
        : undefined,
    [onInteract]
  );

  useEffect(() => {
    const targets = elementRef.current?.querySelectorAll(selector);

    targets?.forEach(target => {
      observer?.observe(target);
    });
  }, [observer, selector]);

  return elementRef;
};

export default useCurrentlyWatchedElementId;
