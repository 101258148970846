import React from 'react';
import { ExcoBoxType } from '../../utils/blogTypes';

export interface ExcoBoxProps extends ExcoBoxType {
  className?: string;
}

const ExcoBox: React.FC<ExcoBoxProps> = ({ className, excoId }) => {
  return (
    <div
      className={`exco ${className}`}
      data-id={excoId}
      data-show-share="false"
      data-show-info="false"
    ></div>
  );
};

export default React.memo(ExcoBox);
