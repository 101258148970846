import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useMemo } from 'react';
import { ContentsItem } from '../../hooks/useTableOfContents';
import ChevronLeft from '../icons/ChevronLeft';
import Text from '../Text';
import './style.scss';

type FixedTableOfContentsProps = {
  contents: ContentsItem[];
  currentItemIndex: number;
  isExpanded: boolean;
  toggleExpanded: () => void;
};

const FixedTableOfContents: React.FC<FixedTableOfContentsProps> = ({
  contents,
  currentItemIndex,
  isExpanded,
  toggleExpanded,
}) => {
  const { t } = useTranslation();

  const renderContentsItem = useCallback<
    (item: ContentsItem, index: number) => JSX.Element
  >(
    (item, index) => (
      <a href={`#${item?.slug}`}>
        <Text
          type="p"
          color="dark"
          weight="demi-bold"
          className={classNames('contents__item', {
            contents__item_current: currentItemIndex === index,
          })}
        >
          {`${Math.max(index + 1, 1)}. ${item?.title?.toUpperCase()}`}
        </Text>
      </a>
    ),
    [currentItemIndex]
  );

  const ContentItems = useMemo(
    () =>
      isExpanded
        ? contents.map(renderContentsItem)
        : renderContentsItem(
            contents[currentItemIndex] ?? contents[0],
            currentItemIndex
          ),
    [contents, currentItemIndex, isExpanded, renderContentsItem]
  );

  return (
    <div
      className={classNames('table', { table_hidden: currentItemIndex < 0 })}
    >
      <Text type="p" color="primary" weight="bold" className="table__title">
        {t('contents')}
      </Text>
      <div className="contents table__contents">{ContentItems}</div>
      <button className="expand-button" onClick={toggleExpanded}>
        <Text
          type="button"
          color="primary"
          weight="bold"
          className="expand-button__text table__title"
        >
          {isExpanded ? t('see_less') : t('see_more')}
        </Text>
        <ChevronLeft
          pathClassName="expand-button__chevron"
          className={classNames('chevron', {
            chevron_expanded: isExpanded,
          })}
        />
      </button>
    </div>
  );
};

export default React.memo(FixedTableOfContents);
