import React from 'react';
import { SnippetBoxType } from '../../utils/blogTypes';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { githubGist } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './style.scss';
import LazyLoad from 'react-lazyload';

export interface SnippetBoxProps extends SnippetBoxType {
  className?: string;
}

const SnippetBox: React.FC<SnippetBoxProps> = ({
  className,
  language,
  body,
}) => {
  return (
    <div className={`snippet-box ${className}`}>
      <LazyLoad offset={100} once>
        <SyntaxHighlighter
          style={githubGist}
          language={language}
          showLineNumbers
          wrapLines
          wrapLongLines
        >
          {body}
        </SyntaxHighlighter>
      </LazyLoad>
    </div>
  );
};

export default React.memo(SnippetBox);
