import * as React from 'react';
import { SVGProps, memo } from 'react';

const ArrowOne = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={115}
    height={70}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.7} fillRule="evenodd" clipRule="evenodd" fill="#fff">
      <path d="M47.306 35.644c.476.064.98.041 1.483.019 2.664.033 5.756.113 8.347-.741 3.408-1.155 5.238-3.128 5.996-5.46 1.065-3.285-.22-7.454-2.833-10.633-2.583-3.168-6.398-5.41-10.138-5.229-2.556.1-5.126 1.345-7.28 4.263-2.46 3.294-2.652 7.16-1.55 10.98.398 1.476 1.02 2.835 1.738 4.226-1.49-.213-2.993-.43-4.517-.655-4.934-.891-9.935-2.38-14.59-4.232-6.183-2.366-11.748-5.674-16.734-10.034-.477-.352-1.177-.3-1.571.143-.394.443-.341 1.131.108 1.57A57.147 57.147 0 0 0 23.15 30.306c4.821 1.811 9.936 3.434 15.037 4.286 2.12.329 4.204.646 6.294.87 1.887 2.838 4.202 5.341 6.295 7.099 14.104 11.45 38.46 10.914 55.681 8.075.599-.086.996-.624.899-1.231-.097-.608-.674-.994-1.272-.907-16.666 2.737-40.258 3.434-53.902-7.669-1.605-1.306-3.336-3.134-4.877-5.185Zm-1.538-2.338c.98.137 1.982.187 3.01.15 2.464-.036 5.284.146 7.642-.595 2.57-.862 4.025-2.29 4.587-4.019.898-2.765-.31-6.046-2.436-8.581-2.126-2.632-5.207-4.626-8.312-4.423-1.989.098-3.93 1.075-5.587 3.298-2.043 2.764-2.138 5.99-1.211 9.08.49 1.795 1.308 3.508 2.307 5.09Z" />
      <path d="M106.964 49.658c-.449.328-1.048.606-1.649.883-1.165.567-2.354 1.03-3.075 1.555-4.401 3.121-9.641 6.92-12.87 11.297-.352.457-.252 1.161.24 1.519.493.357 1.172.298 1.553-.245 3.078-4.141 8.12-7.72 12.371-10.795.76-.511 2.198-1.082 3.384-1.641 1.022-.52 1.89-1.09 2.371-1.792.3-.378.388-1.211-.408-2.054-1.143-1.15-4.757-3.037-5.098-3.247-3.453-2.12-6.457-4.087-7.72-8.154-.195-.545-.823-.851-1.378-.75-.583.187-.918.841-.722 1.387 1.45 4.608 4.733 7.053 8.68 9.434.273.092 2.438 1.203 3.85 2.253.142.143.331.207.471.35Z" />
    </g>
  </svg>
);

export default memo(ArrowOne);
