import * as React from 'react';
import { SVGProps, memo } from 'react';

const ArrowThree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={149}
    height={131}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.7}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.637 28.012c16.312-7.102 34.115-11.1 50.015-6.403 5.84 1.726 10.899 5.049 16.105 8.39a.985.985 0 0 1-1.063 1.66c-5.048-3.241-9.94-6.488-15.598-8.159-15.66-4.626-33.195-.517-49.226 6.56.018.152.039.305.06.458.451 3.361-1.158 7.807-3.897 12.267-3.974 6.476-10.273 13.026-15.415 16.31-2.37 1.517-4.535 2.333-6.156 2.27-1.351-.05-2.403-.634-3.066-1.822-.545-.98-.738-2.13-.607-3.405.176-1.703.942-3.658 2.14-5.713 4.433-7.603 14.794-16.698 19.934-19.252 1.61-.8 3.24-1.575 4.89-2.322-.106-.962-.21-1.881-.391-2.746-.265-1.27-.694-2.414-1.614-3.362-1.699-1.749-4.015-2.454-6.501-2.587-3.981-.215-8.391 1.024-11.693 2.183-18.57 6.519-30.196 21.97-38.048 38.238C9.563 77.028 5.478 94.337 2.094 104.35a.987.987 0 0 1-1.868-.63c3.403-10.069 7.522-27.464 15.504-44C23.803 43 35.815 27.18 54.903 20.478c3.513-1.231 8.214-2.518 12.45-2.291 2.995.163 5.762 1.075 7.806 3.181 1.193 1.228 1.788 2.692 2.131 4.331.154.735.256 1.51.347 2.312Zm-1.645 2.892c.348 3.003-1.212 6.901-3.637 10.85-3.82 6.223-9.858 12.526-14.797 15.683-1.656 1.057-3.168 1.76-4.413 1.927-.9.122-1.628-.061-2.027-.78-.46-.825-.492-1.833-.257-2.955.27-1.292.9-2.72 1.772-4.213 4.25-7.292 14.177-16.03 19.105-18.478a158.7 158.7 0 0 1 4.254-2.034Z"
      fill="#fff"
    />
    <path
      opacity={0.7}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.739 33.295c.023-.455-.084-1.061-.22-1.702-.305-1.451-.834-3.04-1.058-3.802a.987.987 0 0 1 1.892-.553c.272.932.972 3.038 1.237 4.72.136.864.146 1.637.008 2.165-.139.516-.423.826-.776 1.015-.373.2-.898.256-1.554.102-.721-.168-1.709-.606-2.829-.919-.939-.263-1.988-.442-3.031-.157a.983.983 0 1 1-.52-1.898c1.956-.537 3.935.063 5.483.613.461.164 1.041.329 1.368.416Z"
      fill="#fff"
    />
  </svg>
);

export default memo(ArrowThree);
