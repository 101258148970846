import React, { CSSProperties, useMemo } from 'react';
import { ButtonBoxType } from '../../utils/blogTypes';
import Button from '../Button';
import OutlinedArrow from '../icons/OutlinedArrow';
import './style.scss';

export interface ButtonBoxProps extends ButtonBoxType {
  className?: string;
}

const ButtonBox: React.FC<ButtonBoxProps> = ({
  text,
  className,
  backgroundColor,
  contentColor,
  link,
}) => {
  const buttonStyle = useMemo<CSSProperties>(
    () => ({
      backgroundColor,
      color: contentColor,
    }),
    [backgroundColor, contentColor]
  );

  return (
    <Button
      href={link}
      style={buttonStyle}
      color="transparent"
      size="medium"
      className={`button-box ${className}`}
    >
      {text}
      <OutlinedArrow stroke={contentColor} className="button-box__arrow" />
    </Button>
  );
};

export default React.memo(ButtonBox);
