import React, { CSSProperties, useMemo } from 'react';
import { InfoBoxType } from '../../utils/blogTypes';
import Markdown from '../Markdown';
import './style.scss';

interface InfoBoxProps extends InfoBoxType {
  className?: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ className, outlineColor, body }) => {
  const containerStyle = useMemo<CSSProperties>(
    () => ({ borderColor: outlineColor }),
    [outlineColor]
  );

  return (
    <div style={containerStyle} className={`info-box ${className}`}>
      <Markdown source={body} />
    </div>
  );
};

export default React.memo(InfoBox);
