import { append, assoc, last, update } from 'ramda';
import { useMemo } from 'react';
import { ArticleBoxType, SideNoteType } from '../utils/blogTypes';

export type PairedBoxType = {
  regularBox?: ArticleBoxType;
  sideNotes?: SideNoteType[];
};

const INITIAL_ACCUMULATOR: PairedBoxType[] = [];

const usePairedBoxes = (articles: ArticleBoxType[]): PairedBoxType[] => {
  const pairedBoxes = useMemo(
    () =>
      articles.reduce((accumulator, current) => {
        const lastPair = last(accumulator) ?? {};

        if (current?._type === 'sideNote') {
          return lastPair?.regularBox
            ? [...accumulator, { sideNotes: [current] }]
            : update(
                -1,
                assoc(
                  'sideNotes',
                  append(current, lastPair?.sideNotes ?? []),
                  lastPair
                ),
                accumulator
              );
        }

        return !lastPair.regularBox && accumulator.length
          ? update(-1, assoc('regularBox', current, lastPair), accumulator)
          : [...accumulator, { regularBox: current }];
      }, INITIAL_ACCUMULATOR),
    [articles]
  );

  return pairedBoxes;
};

export default usePairedBoxes;
